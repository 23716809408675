import { FC, useState } from 'react';

import { MQImage } from '@mentorcliq/ui';

import ROUTES from 'routes';

import { STATIC_ASSETS } from 'definitions/assets';

import history from 'helpers/history';

import { useAppViewAs } from 'hooks/useAppViewAs';

import MQButton from 'modules/MQButton';
import MQGrid from 'modules/MQGrid';
import MQIcon from 'modules/MQIcon';
import MQModal from 'modules/MQModal';
import MQTypo from 'modules/MQTypo';

import AppFormattedMessage from 'formatters/AppFormattedMessage';

const ApiTimeoutModal: FC = () => {
  const [showModal, setShowModal] = useState(true);
  const { viewingUserId } = useAppViewAs();

  return (
    <MQModal show={showModal} hideOnBackdropClick={false} closableKeys={[]}>
      <MQModal.Body>
        <MQGrid.FlexBox direction="column" align="center">
          <MQTypo.Title type="h2" className="color-brand-indigo" bold center>
            <AppFormattedMessage
              defaultMessage="Mentoring in High Demand — Hold Tight!"
              description="[ApiTimeoutModal] Title"
              id="api.timeout.modal.title"
            />
          </MQTypo.Title>
          <MQTypo.Text size="5x" center>
            <AppFormattedMessage
              defaultMessage="Your platform is experiencing high mentoring demand right now. Sorry for the delay and thank you for your patience! Please try again shortly—mentoring is just around the corner!"
              description="[ApiTimeoutModal] Description"
              id="api.timeout.modal.description"
            />
          </MQTypo.Text>
          <MQImage src={STATIC_ASSETS.images.errors.image429} width={220} />
        </MQGrid.FlexBox>
      </MQModal.Body>
      <MQModal.Footer>
        <MQButton
          dataTestId="api-timeout-modal-back-to-my-cliq-button"
          variant="secondary"
          onClick={() => {
            setShowModal(false);
            history.mq.push(ROUTES.myCliQ.convert({ search: { participantId: viewingUserId } }));
          }}
          startIcon={<MQIcon.Svg icon="arrow-left" />}
        >
          <AppFormattedMessage
            defaultMessage="Back to My CliQ"
            description="[ApiTimeoutModal] Back to my cliq button text"
            id="api.timeout.modal.back.to.my.cliq.button.text"
          />
        </MQButton>
        <MQButton
          dataTestId="api-timeout-modal-refresh-page-button"
          onClick={() => {
            setShowModal(false);
            window.location.reload();
          }}
          startIcon={<MQIcon.Svg icon="rotate" />}
        >
          <AppFormattedMessage
            defaultMessage="Refresh Page"
            description="[ApiTimeoutModal] Refresh page button text"
            id="api.timeout.modal.refresh.page.button.text"
          />
        </MQButton>
      </MQModal.Footer>
    </MQModal>
  );
};

export default ApiTimeoutModal;
