import './style.scss';

import { FC, useMemo } from 'react';

import { ChatRoomDto, getAuthProfileSelector, MatchStatusEnum } from '@mentorcliq/storage';
import { FormattedMessage } from 'react-intl';

import { APP_IMAGE_ALTS } from 'definitions/assets';
import { APP_GLOBAL_MESSAGES } from 'definitions/messages';

import { useAppSelector } from 'hooks/useAppSelector';

import MQAccordion from 'modules/MQAccordion';
import MQAvatar from 'modules/MQAvatar';
import MQLayout from 'modules/MQLayout';
import MQTypo from 'modules/MQTypo';

import EmptyList from 'components/EmptyList';

import ChatFilters from '../ChatFilters';
import ChatMenu from '../ChatMenu';

interface ChatRoomsParams {
  keyword: string;
}

interface ChatRoomsProps {
  ownerId: number;
  activeId: number;
  rooms: Record<string, ChatRoomDto>;
  params: ChatRoomsParams;
  setParams: (data: ChatRoomsParams) => void;
  onOpenRoom: (id: number) => void;
  onCloseChat?: () => void;
}

const ChatRooms: FC<ChatRoomsProps> = ({ ownerId, activeId, rooms, params, setParams, onOpenRoom }) => {
  const authProfile = useAppSelector(({ auth }) => getAuthProfileSelector(auth));

  const filtered = useMemo(() => {
    const keyword = params.keyword.toLowerCase();
    return Object.values(rooms)
      .filter(({ match }) =>
        match.members.some(({ userBasicInfo }) => {
          const name = userBasicInfo.realName.toLowerCase();
          const email = userBasicInfo.email.toLowerCase();
          return name.includes(keyword) || email.includes(keyword);
        }),
      )
      .sort((prev, next) => {
        if (next.messages?.length && prev.messages?.length) {
          const nextLastMessage = next.messages[next.messages.length - 1];
          const prevLastMessage = prev.messages[prev.messages.length - 1];

          return prevLastMessage.created - nextLastMessage.created;
        }

        return 0;
      });
  }, [params.keyword, rooms]);

  const current = useMemo(
    () => filtered.filter(({ match }) => match.matchStatus === MatchStatusEnum.Active),
    [filtered],
  );

  const archives = useMemo(
    () => filtered.filter(({ match }) => match.matchStatus !== MatchStatusEnum.Active),
    [filtered],
  );

  return (
    <div className="app-chat__rooms">
      <div className="app-chat__rooms-header">
        <MQLayout.Heading
          startIcon={
            <MQAvatar.Image
              size="sm"
              imageUrl={authProfile?.picture ?? ''}
              title={authProfile?.name ?? APP_IMAGE_ALTS.profilePicture}
            />
          }
          variant="neutral"
          nowrap
        >
          <MQTypo.Text size="3x" bold>
            <FormattedMessage defaultMessage="Messages" description="[Chat] messages label" id="chat.messages.label" />
          </MQTypo.Text>
        </MQLayout.Heading>
      </div>
      <div className="app-chat__rooms-body">
        <div className="app-chat__rooms-filters">
          <ChatFilters
            filters={{
              keyword: params.keyword,
            }}
            setFilters={(data) => {
              setParams({
                ...params,
                keyword: data.keyword,
              });
            }}
          />
        </div>
        <div className="app-chat__rooms-items">
          {!filtered.length && (
            <div className="app-chat__rooms-empty">
              <EmptyList
                label={
                  <FormattedMessage
                    defaultMessage="users"
                    description="[Chat] members filter empty label"
                    id="chat.filter.empty.membres.label"
                  />
                }
                onClearFilter={() => {
                  setParams({
                    keyword: '',
                  });
                }}
                centered
              />
            </div>
          )}
          {!!current.length && (
            <ChatMenu ownerId={ownerId} activeId={activeId} onOpenRoom={onOpenRoom} items={current} />
          )}

          {!!archives?.length && (
            <MQAccordion defaultActiveKey="concluded">
              <MQAccordion.Item
                header={<FormattedMessage {...APP_GLOBAL_MESSAGES.chatConcludedRelationships} />}
                eventKey="concluded"
                arrow="left"
              >
                <ChatMenu ownerId={ownerId} activeId={activeId} onOpenRoom={onOpenRoom} items={archives} />
              </MQAccordion.Item>
            </MQAccordion>
          )}
        </div>
      </div>
    </div>
  );
};

export default ChatRooms;
